
import { useEffect, useState } from 'react';
import bg from '../assets/images/bg.jpg';
// import google from './assets/images/google.svg';
import logo from '../assets/images/logo.svg';
import { useGlobalContext } from '../context/ContextProvider';
// import video from '../assets/videos/video.mp4';
import { Inputs256 } from "../components/Inputs256";
import { useNavigate } from "react-router-dom";
const DATE_OF_BARTH = {
  DD: Array.from({ length: 31 }, (v, i) => 1 + i),
  MM: Array.from({ length: 12 }, (v, i) => 1 + i),
  YYYY: Array.from({ length: 2024 - 1950 + 1 }, (v, i) => 2024 - i)
}
export default function Signin() {
  const context = useGlobalContext();
  const navigate = useNavigate()
  const [isLogin, setLogin] = useState(true);
  const [getEmail, setEmail] = useState(process.env.NODE_ENV === "development" ? "music.alsheikhaminulislam@gmail.com" : "");
  const [getPaswword, setPaswword] = useState(process.env.NODE_ENV === "development" ? "12345678" : "");
  const [getRePaswword, setRePaswword] = useState(process.env.NODE_ENV === "development" ? "12345678" : "");
  const [isEmailVerification, setEmailVerification] = useState(false);
  const [isLoadingButton, setLoadingButton] = useState(false);
  const [getEmailOtp, setEmailOtp] = useState("");
  const [getPositionSignUp, setPositionSignUp] = useState(0);
  const [getFirstName, setFirstName] = useState(process.env.NODE_ENV === "development" ? "fn" : "");
  const [getLastName, setLastName] = useState(process.env.NODE_ENV === "development" ? "ln" : "");
  const [getSelectDD, setSelectDD] = useState(process.env.NODE_ENV === "development" ? 10 : 0);
  const [getSelectMM, setSelectMM] = useState(process.env.NODE_ENV === "development" ? 10 : 0);
  const [getSelectYYYY, setSelectYYYY] = useState(process.env.NODE_ENV === "development" ? 2007 : 0);
  const [error, seterror] = useState("");

  const [getGender, setGender] = useState("");
  const [getAccountType, setAccountType] = useState("personal");

  function SignInSignUp() {
    if (isLogin) {
      if (getEmail === "") {
        seterror("Invlaid email address.");
        return;
      }
      if (getPaswword === "") {
        seterror("Invlaid password.");
        return;
      }
      seterror("");
      setLoadingButton(!isLoadingButton)
      setTimeout(() => {
        context.https.post("sandbox.valoxity.com/signinToAccount",
          {
            cmail: getEmail,
            password: getPaswword,
          }
        ).then(() => {
          setLoadingButton(false)
          if (context.https.data.success) {
            navigate("/dashboard/welcome")
          } else {
            seterror(context.https.data.ms);
          }
        })
      }, 1000)
    } else {
      if (getPositionSignUp === 1) {
        if (getEmail === "") {
          seterror("Invlaid email address.");
          return;
        }
        if (getPaswword === "") {
          seterror("Invlaid password.");
          return;
        }
        if (getRePaswword === "") {
          seterror("Invlaid re-password.");
          return;
        }
        if (getPaswword !== getRePaswword) {
          seterror("Password are not match.");
          return;
        }
        seterror("");
        setPositionSignUp(2);
      } else if (getPositionSignUp === 2) {
        if (getFirstName === "") {
          seterror("Invlaid first name.");
          return;
        }
        if (getLastName === "") {
          seterror("Invlaid last name.");
          return;
        }
        seterror("");
        setPositionSignUp(3);
      } else if (getPositionSignUp === 3) {
        if (getSelectDD === 0) {
          seterror("Invlaid DD.");
          return;
        }
        if (getSelectMM === 0) {
          seterror("Invlaid MM.");
          return;
        }
        if (getSelectYYYY === 0) {
          seterror("Invlaid YYYY.");
          return;
        }
        if (getGender === "") {
          seterror("Invlaid gender.");
          return;
        }
        if (getAccountType === "") {
          seterror("Invlaid account type.");
          return;
        }
        seterror("");
        sendOtp()
        setPositionSignUp(4);
      } else if (getPositionSignUp === 4) {
        setLoadingButton(!isLoadingButton)
        setTimeout(() => {
          context.https.post("sandbox.valoxity.com/createNewAccount",
            {
              verifyCode: getEmailOtp,
              cmail: getEmail,
              username: getEmail.split("@")[0],
              fname: getFirstName,
              lname: getLastName,
              cday: getSelectDD,
              cmonth: getSelectMM,
              cyear: getSelectYYYY,
              password: getPaswword,
              rpassword: getRePaswword,
              cgender: getGender,
              ctype: getAccountType,
            }
          ).then(() => {
            setLoadingButton(false)
            if (context.https.data.success) { 
              navigate("/dashboard/welcome")
            }
          })
        }, 1000)
      }
    }
  }
  function sendOtp() {
    setLoadingButton(!isLoadingButton)
    context.https.post("sandbox.valoxity.com/sendAuthCode", { mail: getEmail }
    ).then(() => {
      setLoadingButton(false)
      if (context.https.data.status) {
        setEmailVerification(true)
      } else {
        setPositionSignUp(1);
        seterror(context.https.data.error);
      }
    })
  }
  useEffect(() => { 
    context.https.post("sandbox.valoxity.com/sandboxToken", {})
      .then(() => {
        if (context.https.data.success) {
          navigate("/dashboard/welcome")
        }
      })
  }, [])

  return (
    <div className="2xl:container h-screen m-auto">
      <div className="hidden fixed inset-0 w-6/12 ml-auto bg-white bg-opacity-70 backdrop-blur-xl lg:block"></div>
      <div className="relative h-full ml-auto lg:w-6/12">
        <div className="m-auto py-12 px-6 sm:p-20 xl:w-10/12">
          <div className="space-y-4">
            <img src={logo} className="w-40" alt="ValoXity logo" />
            <p className="font-medium text-lg text-gray-600">Welcome to back ! </p>
          </div>

          <div className="space-y-6 py-6">

            <p className="font-medium text-sm w-full text-center text-red-600">{error}</p>
            {isLogin ?
              <Inputs256
                type="email" placeholder="Email"
                get={getEmail} set={setEmail} readOnly={false} /> :
              getPositionSignUp === 1 ?
                <Inputs256
                  type="email" placeholder="Email"
                  get={getEmail} set={setEmail} readOnly={false} /> : null}

            {isLogin ?
              <Inputs256
                type="password" placeholder="Password"
                get={getPaswword} set={setPaswword} readOnly={false} /> :
              getPositionSignUp === 1 ?
                <Inputs256
                  type="password" placeholder="Password"
                  get={getPaswword} set={setPaswword} readOnly={false} /> : null}

            {!isLogin && getPositionSignUp === 1 && (<Inputs256
              type="password" placeholder="Re-Password"
              get={getRePaswword} set={setRePaswword} readOnly={false} />)}



            {!isLogin && getPositionSignUp === 2 && (
              <Inputs256
                type="text" placeholder="First Name"
                get={getFirstName} set={setFirstName} readOnly={false} />
            )}
            {!isLogin && getPositionSignUp === 2 && (
              <Inputs256
                type="text" placeholder="Last Name"
                get={getLastName} set={setLastName} readOnly={false} />
            )}
            {!isLogin && getPositionSignUp === 2 && (
              <Inputs256
                type="text" placeholder="Username"
                get={getEmail.split("@")[0]} set={setEmail} readOnly={true} />
            )}


            {!isLogin && isEmailVerification && getPositionSignUp === 4 ?
              <Inputs256
                type="text" placeholder="OTP"
                get={getEmailOtp} set={setEmailOtp} readOnly={false} /> : null}

            {!isLogin && getPositionSignUp === 3 && (<div className="flex  items-center ">
              <select className="select select-bordered w-full max-w-xs mx-1"
                onChange={(e) => setSelectDD(Number(e.target.value))} 
                defaultValue={"DD"}>
                <option disabled value={"DD"}>DD</option>
                {DATE_OF_BARTH.DD.map((data, index) => <option key={index} value={data} >{data}</option>)}
              </select>
              <select className="select select-bordered w-full max-w-xs mx-1"
                onChange={(e) => setSelectMM(Number(e.target.value))} 
                defaultValue={"MM"}>
                <option disabled value={"MM"}>MM</option>
                {DATE_OF_BARTH.MM.map((data, index) => <option key={index} value={data} >{data}</option>)}
              </select>
              <select className="select select-bordered w-full max-w-xs mx-1"
                onChange={(e) => setSelectYYYY(Number(e.target.value))} 
                defaultValue={"YYYY"}>
                <option disabled value={"YYYY"}>YYYY</option>
                {DATE_OF_BARTH.YYYY.map((data, index) => <option key={index} value={data} >{data}</option>)}
              </select>
            </div>)}

            {!isLogin && getPositionSignUp === 3 && (<div className="flex  items-center ">
              <select className="select select-bordered w-full mx-1"
                onChange={(e) => setGender(e.target.value)}
                defaultValue={"Gender"} >
                <option disabled value={"Gender"}>Gender</option>
                <option value={"male"}>Male</option>
                <option value={"female"}>Females </option>
                <option value={"androgyne"}>Androgynes</option>
              </select>
              {/* <select className="select select-bordered w-full max-w-xs mx-1"
                onChange={(e) => setAccountType(e.target.value)} 
                defaultValue={"Account Type"}>
                <option disabled value={'Account Type'}>Account Type</option>
                <option value={"personal"}>Personal</option>
                <option value={"business"}>business</option>
              </select> */}
            </div>)}  
            <div>
              {!isLoadingButton ?
                <button
                  onClick={() => SignInSignUp()}
                  className="w-full px-6 py-3 rounded-xl bg-orange-500 transition hover:bg-orange-600 focus:bg-orange-600 active:bg-orange-800">
                  <span className="font-semibold text-white text-lg">{isLogin ? "SignIn" : "SignUp"}</span>
                </button> :
                <button
                  className="w-full px-6 py-3 rounded-xl bg-orange-500 transition hover:bg-orange-600 focus:bg-orange-600 active:bg-orange-800">
                  <span className="loading loading-ring loading-lg text-white"></span>
                </button>
              }

              <div className="w-max p-3 -ml-3 cursor-pointer" onClick={() => { setPositionSignUp(1); setLogin(!isLogin); }}>
                <span className="text-sm tracking-wide text-orange-600">{isLogin ? "Create new account" : "I have an account"}</span>
              </div>
            </div>
          </div>

          <div className="border-t pt-12">
            <div className="space-y-2 text-center">
              <img src={logo} className="w-40 m-auto grayscale" alt="" />
              <span className="block text-sm tracking-wide text-gray-500">ValoXity offers new refill facility every month free.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}