// ProtectedRoute.tsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation} from "react-router-dom";
import { useGlobalContext } from './ContextProvider';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const context = useGlobalContext() 
    const uselocation  = useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(false); 
    const navigate = useNavigate();
    const url = `${context.holder.host()}${context.holder.apiPoint()}/sandbox.valoxity.com/sandboxToken`;

    useEffect(() => { 
        async function validateToken() {
            try {

                await context.https.post("sandbox.valoxity.com/sandboxToken",{}); 
                setIsAuthenticated(context.https.data.status)
                return context.https.data.status;
                // return false;
            } catch (error) {
                console.error('Token validation error:', error); 
                setIsAuthenticated(false)
                return false;
            }
        } 
 
        validateToken().then(isValid => { 
            if (!isValid) { 
                navigate("/signin");
            }else{
                if(uselocation.pathname==="/"){
                    navigate("/dashboard/welcome");
                }else if(uselocation.pathname==="/signin"){
                    navigate("/dashboard/welcome");
                } 
            }
        });
    }, [isAuthenticated]);

    if (!isAuthenticated) {
        // Show loading spinner or redirect if not authenticated
        return <div className="jR8x9d nyoS7c fVfPj" > 
        <div className="flex justify-center items-center min-h-screen">
          <div className="w-32 h-32">
            <span className="loading loading-ring loading-lg"></span>
          </div>
        </div>
      </div>;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
