import { useNavigate } from "react-router-dom"

interface Props {
  children: React.ReactNode
  name: string,
  href:string
} 
const NavigationLink = ({ children, name, href }: Props) => {
  const navigate = useNavigate()
  return (
    <div
      onClick={()=>navigate(href)}
      className=" 
      flex p-1 rounded cursor-pointer stroke-[0.75] hover:stroke-neutral-100 
      stroke-black text-black hover:text-neutral-100  place-items-center gap-3 
      hover:bg-orange-500/50 transition-colors duration-100"
    >
      {children}
      <p className="text-inherit font-poppins overflow-clip truncate whitespace-nowrap tracking-wide text-base">
        {name}
      </p>
    </div>
  )
}

export default NavigationLink