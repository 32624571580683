import Navigation from "../components/Navigation";
import { Chart, ArcElement, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2'; // Import Line component

// Register Chart.js components
Chart.register(...registerables);
Chart.register(ArcElement);

const data2 = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  datasets: [{
    label: 'Analytics',
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    fill: true,
    borderColor: 'rgb(249 115 22 / 0.5)',
    tension: 0.1
  }]
};

export default function Welcome() {
  return (
    <main className="w-full h-screen flex flex-row relative">
      <Navigation />
      <section className="flex flex-col p-10 ml-20 w-full gap-5">
        <h1 className="text-4xl text-orange-200">Dashboard</h1>
        <div className="w-full h-80 border border-orange-500/50  rounded">
          <Line
            data={data2}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: true
                },
              },
            }}
            className="  top-0 left-0 w-full h-full"
          />
        </div>
        <div className="flex flex-row gap-5 w-full">
          <div className="border-orange-500/50 h-60 w-1/2  rounded border" >
            <Line
              data={data2}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: true
                  },
                },
              }}
              className="  top-0 left-0 w-full h-full"
            />
          </div>
          <div className="border-orange-500/50 h-60 w-1/2  rounded border" >
            <Line
              data={data2}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: true
                  },
                },
              }}
              className="  top-0 left-0 w-full h-full"
            />
          </div>
        </div>
      </section>
    </main>
  );
}
