import { useEffect, useState } from "react";
import Navigation from "../components/Navigation"
import { useGlobalContext } from "../context/ContextProvider"
export default function Setting() {
    const context = useGlobalContext();
    const [getKeySecret, setKeySecret] = useState("")
    useEffect(() => {
        context.https.post("sandbox.valoxity.com/getAccountSetting")
            .then(() => { 
                if (context.https.data.success) {
                    setKeySecret(context.https.data.data.secretKey)
                }
            })
    }, [])

    function copyKey(params: any) {
        navigator.clipboard.writeText(params)
            .then(() => { console.log('Text copied to clipboard'); })
            .catch(err => {  console.error('Failed to copy text: ', err); });
    }
    return (
        <main className="w-full h-screen flex flex-row relative">
            <Navigation />
            <section className="flex flex-col p-10 ml-20 w-full gap-5">
                <div className="text-4xl text-orange-500/50">Setting</div>
                <div className="flex flex-row gap-5 w-full">
                    <div className="border-orange-500 h-60 w-1/2   rounded border p-3" >
                        <div className="text-xl ">Secrect Key</div>
                        <label className="input input-bordered flex items-center gap-2 w-11/12">
                            <input type="text"
                                className="grow flex overflow-clip place-items-center justify-between w-full text-inherit truncate whitespace-nowrap tracking-wide"
                                placeholder="Search" 
                                readOnly
                                value={getKeySecret}
                            />
                            <svg
                                onClick={() => copyKey(getKeySecret)}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none" viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-6 cursor-pointer">
                                <path strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" />
                            </svg>
                        </label>
                    </div>
                    {/* <div className="border-orange-500 h-60 w-1/2 rounded border" /> */}
                </div>
                {/* <div className="w-full h-80 border border-orange-500 rounded" /> */}
            </section>
        </main>
    )
}