import { createBrowserRouter } from 'react-router-dom'; 
import ProtectedRoute from './ProtectedRoute';
import App from '../App'; // Adjust the import path as needed
import Signin from '../pages/Signin'; // Adjust the import path as needed
import Welcome from '../pages/Welcome'; // Adjust the import path as needed
import Apikeys from '../pages/Apikeys'; // Adjust the import path as needed
import Setting from '../pages/Setting'; 

// Define the routes with type annotations
const Router = createBrowserRouter([
    {
        path: '/',
        element: <ProtectedRoute><App /></ProtectedRoute>,
        // element: <App />,
        children: []
    }, 
    {
        path: '/signin',
        element: <Signin />,
    }, 
    {
        path: '/dashboard/welcome',
        element: <ProtectedRoute><Welcome /></ProtectedRoute>,
    }, 
    {
        path: '/dashboard/apikeys',
        element: <ProtectedRoute><Apikeys /></ProtectedRoute>,
    }, 
    {
        path: '/dashboard/setting',
        element: <ProtectedRoute><Setting /></ProtectedRoute>,
    }, 
  
]);

export default Router;

